<template>
  <v-container class="app-page">
    <v-card class="pt-3 mb-3 form-inputs-disabled-solid">
      <h1 class="titulo">Declaraciones</h1>
      <v-data-table
        :headers="headers"
        :items="items"
        :single-expand="true"
        :expanded.sync="expanded" 
        show-expand
        item-key="idDeclaracion"
        class="app-table"
        :hide-default-footer="true"
        mobile-breakpoint="0"
      >
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0 expanded-with-card">
              <v-card class="pa-6 form-dense">
                <v-row>
                  <v-col cols="12" sm="6" class="py-0">  
                    <v-text-field label="Rubro" color="secondary" :value="item.Rubro" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Monto/Cant Anual" color="secondary" :value="item.Valor" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Relación Filial" color="secondary" :value="item.RelaFilial" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Causal" color="secondary" :value="item.Causal" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Desde" color="secondary" :value="item.Desde" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Presentado en la Empresa" color="secondary" :value="item.PresEmp" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Hasta" color="secondary" :value="item.Hasta" readonly dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-text-field label="Concepto de Baja" color="secondary" :value="item.CausalBaja" readonly dense></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </td>
        </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { getDeclaraciones } from "@/api/core";
import { gnsSmallDate } from "@/filters/index";

export default {
  name: "declaraciones",
  data() {
    return {
      expanded: [],
      loading: false,
      items: [],
      headers: [
        {
          text: "N°",
          align: "center",
          sortable: false,
          value: "Id_Persona",
        },
        { text: "Tipo Declaración", align: "center", value: "Rubro" },
        { text: "Régimen", value: "DescRegimen" },
        { text: "Vinculado a", value: "ApeNomRela" },
        { text: "N° Doc", value: "NDocRela", align: "center" },
        { text: '', value: 'data-table-expand' },
      ]
    };
  },

  created() {},

  watch: {},

  computed: {},

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Declaraciones");
    this.$store.commit("stopProcessing");
    this.getList();
  },

  filters: {
    gnsDate: function (value) {
      return gnsSmallDate(value);
    },
  },

  methods: {
    formatDate (datetime) {
      if (!datetime) return null

      const [date, time] = datetime.split(' ')
      const [year, month, day] = date.split('-')
      if(time)
        return `${day}/${month}/${year} ${time}`
      return `${day}/${month}/${year}`
    },
    
    async getList() {
      this.loading = true;

      const data = await getDeclaraciones();
      if (data && data.length > 0) {
        this.items = this.items.concat(data);
      }

      this.loading = false;
    },

    navigate(item) {
      this.$router.push({
        name: "detalle_liquidacion",
        params: { id: item.id_Encabezado },
      });
    },
  },
};
</script>


<style scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.v-toolbar {
  box-shadow: none;
  border-radius: 0;
  background-color: #fafafa !important;
}

.v-data-table__expanded__content .v-card{
  border-radius: 0;
  background-color: white !important;
  margin: 1rem;
}

.expanded-with-card{
  background: lightgray;
}
</style>
